import React, { useEffect, useState } from 'react'
import { withRouter, useLocation, Link } from 'react-router-dom'
import styles from './Page.module.css'
import cn from 'classnames'

import Header from '../Header'
import Footer from '../Footer'

const Page = ({ children, footerSmall }) => {
  return (
    <div className={cn(styles['page-container'])}>
      <Header />
      {children}
      <Footer small={footerSmall} />
    </div>
  )
}

export default withRouter(Page)
