import React, { useState, useEffect, useRef, useContext } from 'react'
import cn from 'classnames'
import styles from './Stake.module.css'
import TextInput from '../../../components/TextInput'
import Button from '../../../components/Button'
import { useStake, useInterval } from '../../../contractv2'
import { ethers } from 'ethers'
import toast from 'react-hot-toast'
import { WalletContext } from '../../../WalletProvider'

const Stakev2 = () => {
  const textRef = useRef()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [balance, setBalance] = useState(0)
  const [totalStaked, setTotalStaked] = useState(0)
  const [staked, setStaked] = useState(0)
  const [earn, setEarn] = useState(0)
  const [potential, setPotential] = useState(0)
  const [action, setAction] = useState('stake')
  const [refresh, setRefresh] = useState(0)
  const [isApproved, setIsApproved] = useState(false)

  const {
    approve,
    isApprove,
    stake,
    withdraw,
    balanceOf,
    stakedAmount,
    earned,
    getReward,
    calculateDailyReward,
    displayTotalStaked
  } = useStake()

  const { wallet_ } = useContext(WalletContext)
  const account = wallet_[0]

  useEffect(() => {
    balanceOf(account)
      .then(_balance => setBalance(ethers.utils.formatUnits(_balance, 9)))
      .catch(e => console.log(e))

    displayTotalStaked()
      .then(_totalStaked => setTotalStaked(ethers.utils.formatUnits(_totalStaked, 9)))
      .catch(e => console.log(e))

    stakedAmount(account)
      .then(_staked => setStaked(ethers.utils.formatUnits(_staked, 9)))
      .catch(e => console.log(e))

    calculateDailyReward(account)
      .then(_potential => setPotential(ethers.utils.formatUnits(_potential, 9)))
      .catch(e => console.log(e))

    earned(account)
      .then(_earn => setEarn(ethers.utils.formatUnits(_earn, 9)))
      .catch(e => console.log(e))
  }, [account, refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isApprove()
      .then(res => {
        if (res === false) setIsApproved(true)
      })
      .catch(e => console.log(e))
  }, [])

  useInterval(
    () => {
      earned(account)
        .then(_earn => setEarn(ethers.utils.formatUnits(_earn, 9)))
        .catch(e => e)
    }, staked > 0 ? 30000 : null,
    () => {
      calculateDailyReward(account)
      .then(_potential => setPotential(ethers.utils.formatUnits(_potential, 9)))
      .catch(e => e)
  }, potential > 0 ? 30000 : null,
  )

  const onApprove = async () => {
    const amount = textRef.current.value

    if (amount === '') return

    setIsSubmiting(true)
    try {
      await approve()
      setIsApproved(true)
    } catch (e) {
      console.log(e)
    }
    setIsSubmiting(false)
  }

  const onSubmit = async () => {
    const amount = textRef.current.value

    if (amount === '') return
    if (amount === '0' ) return toast("execution reverted: You cannot stake zero tokens.")

    setIsSubmiting(true)
    try {
      await stake(amount)
      setRefresh(new Date())
      toast('Staked successfully.')
    } catch (e) {
      toast(e.data.message)
      console.log(e)
    }
    setIsSubmiting(false)
  }

  const onSubmitUnstake = async () => {
    const amount = textRef.current.value

    if (amount === '') return
    if (amount === '0' ) return toast("execution reverted: You cannot unstake zero tokens.")
    setIsSubmiting(true)
    try {
      await withdraw(amount)
      setRefresh(new Date())
      toast('Unstaked successfully.')
    } catch (e) {
      if(e.message === "Internal JSON-RPC error.") {
        if(amount > staked) {
          toast("execution reverted: You cannot unstake a higher amount than that is currently staked.")
        } else {
          toast(e.data.message)
        }
      } else {
        toast(e.message)
      }
      console.log(e)
    }
    setIsSubmiting(false)
  }

  const handleRewards = async () => {
    try {
      await getReward()
      setRefresh(new Date())
      toast('Reward received successfully.')
    } catch (e) {
      toast(e.data.message)
      console.log(e)
    }
  }

  const handleTab = action => setAction(action)

  const handleMax = () => {
    if (action === 'stake') {
      textRef.current.value = balance
    }
    if (action === 'unstake') {
      textRef.current.value = staked
    }
  }

  return (
    <>
      <div className={cn(styles['stake-container'])}>
        <div className={cn(styles['stake'])}>
          <div className={cn(styles['stakeDivOne'])}>
          <h1>V2</h1>
            <h1>
              STAKE HMMM <span> EARN MORE</span>
            </h1>
          </div>
          <div className={cn(styles['stackBackground'])}>
            <div className={cn(styles['stack-tab-container'])}>
              <div className={cn(styles['one'])}>
                <p>APY</p>
                <h2>24%</h2>
              </div>

              <div className={cn(styles['two'])}>
                <p>Total Staked</p>
                <h2>SOON HMMM</h2>
              </div>
              <div className={cn(styles['three'])}>
                <p>Next Milestone</p>
                <h2>$10.00</h2>
              </div>
            </div>

            <div className={cn(styles['tabs-wrapper'])}>
              <div className={cn(styles['tabs'])}>
                <p
                  className={action === 'stake' ? cn(styles['active']) : ''}
                  onClick={() => handleTab('stake')}>
                  Stake
                </p>
                <p
                  className={action === 'unstake' ? cn(styles['active']) : ''}
                  onClick={() => handleTab('unstake')}>
                  Unstake
                </p>
              </div>
            </div>

            <div className={cn(styles['input-container-main'])}>
              <div className={cn(styles['input-container'])}>
                <TextInput
                  className={cn(styles['text-container'])}
                  placeholder={'Amount'}
                  ref={textRef}
                />
                <Button label={'Max'} onClick={handleMax} />
              </div>
              <div className={cn(styles['stake-button'])}>
                {action === 'stake' ? (
                  isApproved === true ? (
                    <Button
                      label={'STAKE HMMM'}
                      primary
                      onClick={onSubmit}
                      isLoading={isSubmiting}
                    />
                  ) : (
                    <Button
                      label={'APPROVE'}
                      primary
                      onClick={onApprove}
                      isLoading={isSubmiting}
                    />
                  )
                ) : (
                  <Button
                    label={'UNSTAKE HMMM'}
                    primary
                    onClick={onSubmitUnstake}
                    isLoading={isSubmiting}
                  />
                )}
              </div>
            </div>

            <div className={cn(styles['information'])}>
              <div>Your Balance</div>
              <div>{balance} HMMM</div>
              <div>Your Staked Balance</div>
              <div>{staked} HMMM</div>

              <div>Reward Earned</div>
              <div>
                <span className={cn(styles['rewards'])} onClick={handleRewards}>
                  Get Rewards
                </span>
                {earn} HMMM
              </div>
              <div>Potential daily rewards</div>
              <div>{potential} HMMM</div>
            </div>
            <div className={cn(styles['legal'])}>
              <p>
                When you stake HMMM you are rewarded in 24% HMMM as a return.
                Your reward can be collected after the seventh (7) day of your
                staking deposit. There is a fourteen (14) day minimum hold for
                staking, after this time you can unstake and see your balance
                reflected back into your wallet. To add to your stake you can
                claim rewards or buy HMMM to stake again without the need of 
                unstaking first.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Stakev2
