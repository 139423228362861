import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Carousel.module.css'
import './Carousel.css'
import { useSwipeable } from 'react-swipeable'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { Container } from 'react-bootstrap'

export const CarouselItem = ({ children, height, className }) => {
  return (
    <div
      className={cn(styles['carousel-item'], className)}
      style={{ height: height }}>
      {children}
    </div>
  )
}

const Carousel = ({ children, hands, className }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [paused, setPaused] = useState(false)

  const updateIndex = newIndex => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0
    }
    setActiveIndex(newIndex)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1)
      }
    }, 11000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  })

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  })

  return (
    <Container fluid className={cn(styles['container'])}>
      <div className={cn(styles['carousel'], className)}>
        {/* <div {...handlers} className={cn(styles['carousel'],className)} onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}> */}
        {/* <div className={cn(styles['inner'])} style={{transform:`translateX(-${(activeIndex - 1) * 238}%)`}}> */}
        <div className={cn(styles['inner'])}>
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, { width: '100%' })
          })}
        </div>
        <div>
          {hands ? (
            <div className={cn(styles['indicators-two'])}>
              <button
                onClick={() => {
                  updateIndex(activeIndex - 1)
                }}>
                <BsArrowLeft />
              </button>

              <button
                onClick={() => {
                  updateIndex(activeIndex + 1)
                }}>
                <BsArrowRight />
              </button>
            </div>
          ) : // <div className={cn(styles['indicators'])}>
          //     {
          //         React.Children.map(children, (child,index)=>{
          //             return(
          //                 <button className={cn(styles['indicators-button'],"active",`${index == activeIndex ? "activeColor" : ""}`)} onClick={()=>updateIndex(index)}>
          //                     {`0${index + 1}`}
          //                 </button>
          //             )
          //         })
          //     }
          // </div>
          null}
        </div>
      </div>
    </Container>
  )
}

export default Carousel
