import React, { Component } from 'react'
import cn from 'classnames'
import styles from './Timer.module.css'

class Timer extends Component {
  constructor(props) {
    super(props)
    this.countDownId = null
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false,
      offsetY: 0
    }
  }

  handleScroll = () => {
    this.setState({
      offsetY: window.pageYOffset
    })
  }

  componentDidMount() {
    this.countDownId = setInterval(this.timerInit, 1000)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    if (this.countDownId) {
      clearInterval(this.countDownId)
    }
  }

  timerInit = () => {
    const { startDate } = this.props
    //console.log(startDate);
    const now = new Date().getTime()
    if (!startDate) {
      this.setState({ expired: true })
      return
    }
    const countDownStartDate = new Date(startDate).getTime()
    const distance = countDownStartDate - now
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) - 6
    )
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    // For countdown is finished
    if (distance < 0) {
      clearInterval(this.countDownId)
      this.setState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: true
      })
      return
    }
    this.setState({ days, hours, minutes, seconds, expired: false })
  }

  render() {
    const { days, hours, minutes, seconds, expired } = this.state
    // if (expired) {
    //   return <div className={cn(styles["expired"])}>Expired :(</div>;
    // }
    return (
      <div className={cn(styles['timer'])}>
        <div
          className={cn(styles['value'])}
          style={{ transform: `translateX(-${this.state.offsetY * 0.024}px)` }}>
          <h2>{days}</h2>
          <span
            style={{
              transform: `translateY(${this.state.offsetY * 0.012}px)`
            }}>
            days
          </span>
        </div>
        <div
          className={cn(styles['seperator'])}
          style={{ transform: `translateX(-${this.state.offsetY * 0.018}px)` }}>
          :
        </div>
        <div
          className={cn(styles['value'])}
          style={{ transform: `translateX(-${this.state.offsetY * 0.012}px)` }}>
          <h2>{hours}</h2>
          <span
            style={{
              transform: `translateY(${this.state.offsetY * 0.012}px)`
            }}>
            hours
          </span>
        </div>
        <div
          className={cn(styles['seperator'])}
          style={{ transform: `translateX(${this.state.offsetY * 0.003}px)` }}>
          :
        </div>
        <div
          className={cn(styles['value'])}
          style={{ transform: `translateX(${this.state.offsetY * 0.012}px)` }}>
          <h2>{minutes}</h2>
          <span
            style={{
              transform: `translateY(${this.state.offsetY * 0.012}px)`
            }}>
            minutes
          </span>
        </div>
        <div
          className={cn(styles['seperator'])}
          style={{ transform: `translateX(${this.state.offsetY * 0.018}px)` }}>
          :
        </div>
        <div
          className={cn(styles['value'])}
          style={{ transform: `translateX(${this.state.offsetY * 0.024}px)` }}>
          <h2>{seconds}</h2>
          <span
            style={{
              transform: `translateY(${this.state.offsetY * 0.012}px)`
            }}>
            seconds
          </span>
        </div>
      </div>
    )
  }
}
export default Timer
