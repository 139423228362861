import React, { useEffect, useState } from "react";
import { withRouter, useLocation, Link } from "react-router-dom";
import styles from "./Footer.module.css";
import cn from "classnames";
import Logo from '../../images/Logo.png'
import tiktok from '../../images/tiktok.png'
import discord from '../../images/discord.png'
import telegram from '../../images/telegram.png'
import twitter from '../../images/twitter.png'
import insta from '../../images/instagram.png'
import { Nav } from "react-bootstrap";


const Footer = ({small}) => {

  console.log(small)
  return (
    <div className={cn(styles["footer-container-small"])}>
          <div>
            <div className={cn(styles['footer-end-small'])}>
              <div><p>© Copyright {new Date().getFullYear()} HMMM - All Rights Reserved</p></div>
              <div className={cn(styles['social-icon-container'])}>
                <Nav.Link href="https://tiktok.com/@cryptobobles?"><div><img src={tiktok} width={"13px"}></img></div></Nav.Link>
                <Nav.Link href="http://discord.gg/hmmmtoken"><div><img src={discord} width={"25px"}></img></div></Nav.Link>
                <Nav.Link href="https://t.me/hmmmtoken"><div><img src={telegram} width={"22px"}></img></div></Nav.Link>
                <Nav.Link href="https://www.instagram.com/boblles.official/"><div><img src={insta} width={"18px"}></img></div></Nav.Link>
                <Nav.Link href="https://twitter.com/official_hmmm"><div><img src={twitter} width={"25px"}></img></div></Nav.Link>
              </div>
            </div>
          </div>
    </div>
  );
};

export default Footer;