import React, { useEffect, useState, useContext } from 'react'
import { withRouter, useLocation, Link } from 'react-router-dom'
import styles from './Header.module.css'
import cn from 'classnames'
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap'
import Logo from '../../images/Logo.png'
import { useWeb3React } from '@web3-react/core'
import { truncate } from '../../utils'
import ConnectWalletModal from '../ConnectWalletModal'
import { WalletContext } from '../../WalletProvider'
import { RiShutDownLine } from 'react-icons/ri'
import { useDisconnect } from '../ConnectWalletModal/hooks'
const Header = ({}) => {
  const { pathname } = useLocation()
  const { wallet_ } = useContext(WalletContext)
  const [visibleModal, setVisibleModal] = useState(false)
  const [wallet] = wallet_

  const disconnect = useDisconnect()

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={cn(styles['header-container'])}>
        <Container fluid className={cn(styles['container'])}>
          <Navbar.Brand href="#home">
            <Link to={'/'}>
              <img className={cn(styles['logo-img'])} src={Logo}></img>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            className={cn(styles['buttonNav'])}
            aria-controls="responsive-navbar-nav"
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={cn(styles['navBarCustom'], 'align-items-center')}>
            <Nav className={cn(styles['navbar-nav'], 'me-auto ms-auto')}>
              <Nav.Link
                href={'/exchange'}
                className={cn(styles['exchange-link'])}>
                Exchange
              </Nav.Link>
              <Nav.Link href="./#contract">Contract</Nav.Link>
              <Nav.Link href="./#roadmap">Roadmap</Nav.Link>
              <Nav.Link
                href="https://hmmm.boblles.com/white-paper.pdf"
                target="_blank">
                Whitepaper
              </Nav.Link>
            </Nav>
            <Nav className={cn(styles['navigation'])}>
              <Nav.Link href={'/stake'} className={cn(styles['stake'])}>STAKE V1</Nav.Link>
              <Nav.Link href={'/v2/stake'} className={cn(styles['stake'])}>STAKE V2</Nav.Link>
              {wallet !== null ? (
                <div className={cn(styles['wallet-wrapper'])}>
                  <div className={cn(styles['wallet-connect'])}>
                    {truncate(wallet, 15)}
                  </div>
                  <RiShutDownLine
                    className={cn(styles['disconnect'])}
                    onClick={() => disconnect()}
                  />
                </div>
              ) : (
                <Nav.Link className={cn(styles['connect'])}>
                  <Link
                    to={'#'}
                    onClick={() => {
                      setVisibleModal(true)
                    }}>
                    Connect Wallet
                  </Link>
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <ConnectWalletModal
        visibleModal={visibleModal}
        setVisibleModal={() => {
          setVisibleModal(false)
        }}
      />
    </>
  )
}

export default Header
