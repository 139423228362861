import React from "react";
import cn from "classnames";
import styles from "./TextInput.module.css";
import style from "./TextInput.css";

const TextInput = React.forwardRef(
  (
    {
      className,
      label,
      error,
      placeholder,
      rounded,
      multiline,
      icon,
      rows,
      labelColor,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cn(styles.field, className, "inputContainer")}>
        {label ? <label style={{color:`${labelColor}`}} className="form-label">{label}</label> : null}

        <div className={cn(styles.wrap, rounded ? "rounded" : "")}>
          {multiline ? (
            <textarea
              className="form-control form-control-textarea"
              placeholder={placeholder ? placeholder : ""}
              {...props}
              ref={ref}
            >
            </textarea>
          ) : (
            <div>
              {
                icon ? (
                <div className="form-control icon">
                  <span>{icon}</span>
                  <input
                    placeholder={placeholder ? placeholder : ""}
                    {...props}
                    ref={ref}
                />
                </div>) 
                : (
                  <input
                  className="form-control"
                  placeholder={placeholder ? placeholder : ""}
                  {...props}
                  ref={ref}
                />
                )
              }
            </div>
          )}
        </div>
        {error && <div className={cn("error")}>{error}</div>}
      </div>
    );
  }
);

export default TextInput;
