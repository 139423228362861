import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './css/Exchange.module.css'
import stylesHeading from './css/Heading.module.css'
import stylesDetails from './css/Details.module.css'
import stylesRoadmap from './css/AppRoadmap.module.css'
import phoneImage from '../../images/exchange/hmmm-landing.jpg'
import Button from '../../components/Button'
import landImage from '../../images/exchange/land.jpg'
import nftImage from '../../images/exchange/nfts.jpg'
import wealthImage from '../../images/exchange/wealth.jpg'
import mainLogo from '../../images/Logo-Main.png'
import promoImage from '../../images/exchange/icon-promotion.svg'
import rewardImage from '../../images/exchange/icon-reward.svg'
import exploreImage from '../../images/exchange/icon-explore.svg'
import experienceImage from '../../images/exchange/icon-experience.svg'
import { Col, Container, Row } from "react-bootstrap";
import Carousel, { CarouselItem } from "../../components/Carousel/Carousel";
import FeatureCardItem from "../../components/FeaturesCardItem";
import { useHistory } from "react-router-dom";
const Exchange = () => {
const history = useHistory();
const handleChange = () => history.push('/stake');
const [offsetY, setOffsetY] = useState(0)
const handleScroll = () => {
setOffsetY(window.pageYOffset)
}
useEffect(() => {
window.addEventListener('scroll', handleScroll)
return () => window.removeEventListener('scroll', handleScroll)
}, [])

return (
<>
<div className={cn(styles['home-container'])}>
  <div className={cn(styles['intro-section'])}>
    <Row>
    <Col lg={6}>
    <div className={cn(styles['hero-container'])}>
      <div>
        <div>
          <div className={cn(styles['hero-badge'])}>
            <span className={cn(styles['gradient-badge'])}>update</span>
            <span>Exchange designs are complete.</span>
          </div>
        </div>
      </div>
      <div>
        <div className={cn(styles['hero-copy'])}>
          <h2 className={cn(styles['hero-headline'])}>Unlocking experiences <span className={cn(styles['special'])}>with AR.</span></h2>
          <p>We are converting the real world into a giant game board where users can discover, collect, and trade NFT's using augmented reality in real-world locations.</p>
        </div>
      </div>
      <div>
        <div className={cn(styles['button-group'])}>
        <Button label={"TOKEN"} primary handleClick={"https://pancakeswap.finance/swap?outputCurrency=0x88Cf50037f8069071Cd56f641a81b369a5Fd3a42"}/>
        <Button label={"WHITEPAPER"} gradient handleClickNewTab={"https://hmmm.boblles.com/white-paper.pdf"}/>
      </div>
      </div>
    </div>
    </Col>
    <Col lg={6}>
    <div className={cn(styles['phone-container'])}>
    <img className={cn(styles['phoneImage'])} src={phoneImage}></img>
    </div>
    </Col>
    </Row>
  </div> 
  <div className={cn(stylesDetails['details-section'])}>
    <div style={{transform:`translateY(${offsetY * 0.020}vh)`}} className={cn(stylesDetails['intro-copy'])}>
        <p><small>Discover</small></p>
        <h3>How It Works</h3>
    </div>
    <div className={cn(stylesDetails['grid-container'])}>
      <Row className={cn(stylesDetails['parallax-container'])}>
      <Col className={cn(stylesDetails['parallax-num'])} lg={3}><h2 className={cn(stylesDetails['z-sky'])}>01</h2></Col>
      <Col className={cn(stylesDetails['parallax-img-1'])} lg={5}><img className={cn(stylesDetails['p-1'])} style={{transform:`scale(${offsetY * .00017 + 1})`}} src={landImage}></img></Col>
      <Col className={cn(stylesDetails['parallax'])} lg={4}>
      <div className={cn(stylesDetails['copy'])}>
        <p><small>Property</small></p>
        <h3>Discover Locations</h3>
        <p>Buying property in HMMM gives users the ability to be a dedicated NFT seller for concert venues, football games, ralleys, activations and more.</p>
      </div>
      </Col>
      </Row>
      <Row className={cn(stylesDetails['parallax-container'])}>
      <Col className={cn(stylesDetails['parallax'])} lg={4}>
      <div className={cn(stylesDetails['copy-right'])}>
        <p><small>Products</small></p>
        <h3>Manage Portfolios</h3>
        <p>Wether you're selling land, leasing venues, offering AR experiences or trading NFT's. HMMM will empower you with the tools to grow your digital emprire.</p>
      </div>
      </Col>
      <Col className={cn(stylesDetails['parallax-img-2'])} lg={5}><img className={cn(stylesDetails['p-2'])} style={{transform:` scale(${offsetY * .00017 + .75})`}} src={nftImage}></img></Col>
      <Col className={cn(stylesDetails['parallax-num'])} lg={3}><h2 className={cn(stylesDetails['tuck-left'])}>02</h2></Col>
      </Row>
      <Row className={cn(stylesDetails['parallax-container'])}>
      <Col className={cn(stylesDetails['parallax-num'])} lg={3}><h2>03</h2></Col>
      <Col className={cn(stylesDetails['parallax-img-3'])} lg={5}><img className={cn(stylesDetails['p-3'])} style={{transform:` scale(${offsetY * .00017 + .75})`}} src={wealthImage}></img></Col>
      <Col className={cn(stylesDetails['parallax'])} lg={4}>
      <div className={cn(stylesDetails['copy'])}>
        <p><small>Profit</small></p>
        <h3>Trade Everything</h3>
        <p>Rare and exclusive NFT's, unique AR experiences, and digital real estate. HMMM is putting the power to build wealth in the palm of your hand.</p>
      </div>
      </Col>
      </Row>
    </div> 
  </div>
  <div style={{transform:`translateY(${offsetY * 0.020 - 70}vh)`}} className={cn(stylesDetails['intro-copy'])}>
        <img className={cn(stylesDetails['features-logo'])} src={mainLogo}></img>
        <h3>Additional Features</h3>
    </div>
   <div className={cn(stylesDetails['features-row'])}>
   <div className={cn(stylesDetails['heading-details'])}>
   <Row>
        
        <Col lg={3}>
        <div className={cn(stylesDetails['features-hero'])}>
        <div className={cn(stylesDetails['features-heading'])}><img className={cn(stylesDetails['icon-quad'])} src={exploreImage}></img>
          <small>Exploration</small></div>
          <p>Items are discovered in the real-world, and users can earn more through exploring their world and uncovering the map.</p>
        </div>
        </Col>
        <Col lg={3}>
        <div className={cn(stylesDetails['features-hero'])}>
        <div className={cn(stylesDetails['features-heading'])}><img className={cn(stylesDetails['icon-quad'])} src={experienceImage}></img>
        <small>Experiences</small></div>
          <p>Original artwork and rare experiences that can only be viewed using AR will make the real-world come alive.</p>
        </div>
        </Col>
        <Col lg={3}>
        <div className={cn(stylesDetails['features-hero'])}>
          <div className={cn(stylesDetails['features-heading'])}><img className={cn(stylesDetails['icon-quad'])} src={rewardImage}></img>
          <small>Rewards</small></div>
          <p>A percentage of every transaction on the platform will reflect back into the wallet of our token holders.</p>
        </div>
        </Col>
        <Col lg={3}>
        <div className={cn(stylesDetails['features-hero'])}>
          <div className={cn(stylesDetails['features-heading'])}><img className={cn(stylesDetails['icon-quad'])} src={promoImage}></img>
          <small>Promotions</small></div>
          <p>Those who hold HMMM will recieve priority access to exclusive content and experiences within the app.</p>
        </div>
        </Col>
        </Row>
        </div>
   </div> 
   <div style={{transform:`translateY(${offsetY * 0.020 - 70}vh)`}} className={cn(stylesDetails['intro-copy'])}>
        <p><small>Roadmap</small></p>
        <h3>App Development Lifecycle</h3>
    </div>
  <div className={cn(stylesRoadmap['roadmap-container'])} id="roadmap">
    <div className={cn(stylesRoadmap['roadmap'])}>
      <div className={cn(stylesRoadmap['hideBorder'])}></div>
      <div className={cn(stylesRoadmap['thickBorder'])} ></div>
      <div className={cn(stylesRoadmap['row-a'])}>
        <div className={cn(stylesRoadmap['content-roadmap-1'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>Token Launch</p>
          <small>November</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-2'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>Initial Concept</p>
          <small>January</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-3'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>UI Design</p>
          <small>February</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-4'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>Component Library</p>
          <small>February</small>
        </div>
      </div>
      <div className={cn(stylesRoadmap['row-b'])}>
        <div className={cn(stylesRoadmap['content-roadmap-5'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>App Launch</p>
          <small>August</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-6'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>Onboarding</p>
          <small>June</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-7'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>Bug Testing</p>
          <small>May</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-8'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>Development Begins</p>
          <small>March</small>
        </div>
      </div>
    </div>
  </div>
</div>
</>
)
}
export default Exchange