export const contracts = {
  TOKEN_ADDRESS: '0x88cf50037f8069071cd56f641a81b369a5fd3a42',
  STAKING_ADDRESS: '0x782651C8cb74d88Ec02ecBbf633f3ceCd2fF121B'
}

export const supportedNetwork = {
  56: {
    rpc: 'https://bsc-dataseed1.defibit.io/'
  }
}

// export const contracts = {
//   TOKEN_ADDRESS: '0x3C7a6D0223Dd73C2a43FB548083AA0BA7A9f5b1F',
//   STAKING_ADDRESS: '0xa368E7F8390AC212Af31598e2B1e2083e178Fc27'
// }
//
// export const supportedNetwork = {
//   56: {
//     rpc: 'https://data-seed-prebsc-2-s3.binance.org:8545/'
//   }
// }

// const rpc = [
//   'https://bsc-dataseed.binance.org/',
//   'https://bsc-dataseed1.defibit.io/',
//   'https://bsc-dataseed1.ninicoin.io/',
//   'https://bsc-dataseed2.defibit.io/',
//   'https://bsc-dataseed3.defibit.io/',
//   'https://bsc-dataseed4.defibit.io/',
//   'https://bsc-dataseed2.ninicoin.io/',
//   'https://bsc-dataseed3.ninicoin.io/',
//   'https://bsc-dataseed4.ninicoin.io/',
//   'https://bsc-dataseed1.binance.org/',
//   'https://bsc-dataseed2.binance.org/',
//   'https://bsc-dataseed3.binance.org/',
//   'https://bsc-dataseed4.binance.org/'
// ]
