import { contracts, supportedNetwork } from './config'
import { useState, useEffect, useLayoutEffect, useRef, useContext } from 'react'
import { ethers } from 'ethers'
import useSWR from 'swr'
import { WalletContext } from '../WalletProvider'
import Web3 from 'web3'
import { useInit } from '../components/ConnectWalletModal/hooks'
import { BscscanProvider } from "@ethers-ancillary/bsc"

const ERC20ABI = require('./abi/erc20.json')
const STAKINGABI = require('./abi/stakingv2.json')

export const useStake = () => {
  const { wallet_ } = useContext(WalletContext)
  const [instance, setInstance] = useState(null)
  const account = wallet_[0]
  const initWeb3 = useInit()

  useEffect(() => {
    const getWeb3 = async () => {
      try {
        const ins = await initWeb3()
        setInstance(ins)
      } catch (e) {
        console.log(e)
      }
    }

    getWeb3()
  }, [])

  const balanceOf = async _account => {
    if (_account === null) return 0

    let inst = instance
    if (instance === null) {
      inst = await initWeb3()
    }

    const library = new ethers.providers.Web3Provider(inst)

    const contract = new ethers.Contract(
      contracts.TOKEN_ADDRESS,
      ERC20ABI,
      library.getSigner(account)
    )
    return await contract.balanceOf(_account)
  }

  const approve = async () => {
    let inst = instance
    if (instance === null) {
      inst = await initWeb3()
    }

    const library = new ethers.providers.Web3Provider(inst)

    const uintMax =
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

    const contract = new ethers.Contract(
      contracts.TOKEN_ADDRESS,
      ERC20ABI,
      library.getSigner(account)
    )

    const value = await contract.allowance(account, contracts.STAKING_ADDRESS)
    if (value.toString() === '0') {
      const tx = await contract.approve(contracts.STAKING_ADDRESS, uintMax)
      await tx.wait()
    }
  }

  const isApprove = async () => {
    let inst = instance
    if (instance === null) {
      inst = await initWeb3()
    }

    const library = new ethers.providers.Web3Provider(inst)

    const contract = new ethers.Contract(
      contracts.TOKEN_ADDRESS,
      ERC20ABI,
      library.getSigner(account)
    )

    const value = await contract.allowance(account, contracts.STAKING_ADDRESS)
    return value.toString() === '0'
  }

  const stake = async amount => {
    let inst = instance
    if (instance === null) {
      inst = await initWeb3()
    }

    const library = new ethers.providers.Web3Provider(inst)

    const contract = new ethers.Contract(
      contracts.STAKING_ADDRESS,
      STAKINGABI,
      library.getSigner(account)
    )

    const tx = await contract.stake(
      ethers.utils.parseUnits(amount.toString(), 9)
    )
    await tx.wait()
  }

  const withdraw = async amount => {
    let inst = instance
    if (instance === null) {
      inst = await initWeb3()
    }

    const library = new ethers.providers.Web3Provider(inst)

    const contract = new ethers.Contract(
      contracts.STAKING_ADDRESS,
      STAKINGABI,
      library.getSigner(account)
    )

    const tx = await contract.Unstake(
      ethers.utils.parseUnits(amount.toString(), 9)
    )
    await tx.wait()
  }

  const stakedAmount = async _account => {
    if (_account === null) return 0

    let inst = instance
    if (instance === null) {
      inst = await initWeb3()
    }

    const library = new ethers.providers.Web3Provider(inst)

    const contract = new ethers.Contract(
      contracts.STAKING_ADDRESS,
      STAKINGABI,
      library.getSigner(account)
    )

    return await contract.TokensStaked(_account)
  }

  const earned = async _account => {
    if (_account === null) return 0

    let inst = instance
    if (instance === null) {
      inst = await initWeb3()
    }

    const library = new ethers.providers.Web3Provider(inst)

    const contract = new ethers.Contract(
      contracts.STAKING_ADDRESS,
      STAKINGABI,
      library.getSigner(account)
    )

    return await contract.CheckRewards(_account)
  }

  const calculateDailyReward = async _account => {
    if (_account === null) return 0

    let inst = instance
    if (instance === null) {
      inst = await initWeb3()
    }

    const library = new ethers.providers.Web3Provider(inst)

    const contract = new ethers.Contract(
      contracts.STAKING_ADDRESS,
      STAKINGABI,
      library.getSigner(account)
    )

    return await contract.CalculateDailyReward(_account)
  }

  

  const getReward = async () => {
    let inst = instance
    if (instance === null) {
      inst = await initWeb3()
    }

    const library = new ethers.providers.Web3Provider(inst)

    const contract = new ethers.Contract(
      contracts.STAKING_ADDRESS,
      STAKINGABI,
      library.getSigner(account)
    )

    const tx = await contract.claimRewards()
    await tx.wait()
  }

  const displayTotalStaked = async () => {
    let inst = instance
    if (instance === null) {
      inst = await initWeb3()
    }

    const library = new ethers.providers.Web3Provider(inst)

    const contract = new ethers.Contract(
      contracts.STAKING_ADDRESS,
      STAKINGABI
    )
    const provider = new BscscanProvider()
    const balance = await provider.getBalance(contracts.STAKING_ADDRESS)
    return balance
  }


  return {
    approve,
    isApprove,
    stake,
    withdraw,
    balanceOf,
    stakedAmount,
    earned,
    getReward,
    calculateDailyReward,
    displayTotalStaked
  }
}

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}



