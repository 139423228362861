import React from 'react'
import cn from "classnames";
import styles from "./FeatureCardItem.module.css";
import Button from '../../components/Button'

function FeatureCardItem({percentage,title,subtitle,buttonLabel,handleClick}) {
    return (
        <div className={cn(styles['feature-container'])}>
            <div className={cn(styles['feature-item-container'])}>
                <h1 className={cn(styles['percentage'])}>{percentage}</h1>
                <h1 className={cn(styles['title'])}>{title}</h1>
                <div className={cn(styles['subtitle'])}><p>{subtitle}</p></div>
            </div>
            <Button label={buttonLabel} gradient full handleClick={handleClick}/>
        </div>
    )
}

export default FeatureCardItem
