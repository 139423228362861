import React, { useEffect ,useRef, useState} from 'react'
import cn from "classnames";
import styles from './BgVideo.module.css'

function BackgroundVideo({ videoSource, children, blur }) {

    const [playing, setPlaying] = useState(false);

    const videoRef = useRef(null);

    const videoHandler = (control) => {
        if (control === "play") {
          videoRef.current.play();
          setPlaying(true);
        } else if (control === "pause") {
          videoRef.current.pause();
          setPlaying(false);
        }
      };

    return (
        <>
            <div className={cn(styles['container'])}>
                {videoSource && 
                <div className={cn(styles['video-main'])}>
                    <video
                        ref={videoRef}
                        style={{ filter: `blur(${blur}px)`, WebkitFilter: `blur(${blur}px)` }}
                        id="video1"
                        // autoplay="autoPlay"
                        // muted
                        className={cn(styles['video'],['maxw'])} >
                        <source src={videoSource} type="video/mp4" />
                            Your browser does not support the video tag.
                    </video>
                {
                    !playing ?  <button onClick={() => videoHandler("play")} className={cn(styles['play'])}><svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none"/><path d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm-4 29V15l12 9-12 9z"/></svg></button> : <button onClick={() => videoHandler("pause")} className={cn(styles['pause'])}><svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none"/><path d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm-2 28h-4V16h4v16zm8 0h-4V16h4v16z"/></svg></button>
                }
                </div>}
                {children}
            </div>
        </>
    )
}


export default BackgroundVideo
