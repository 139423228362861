import { useState, useEffect, useContext } from 'react'
import Web3 from 'web3'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { ethers } from 'ethers'
import { WalletContext } from '../../WalletProvider'

const providerOptions = {
  injected: {
    display: {
      name: 'Metamask',
      description: 'Connect with the provider in your Browser'
    },
    package: null
  },
  walletconnect: {
    display: {
      name: 'Wallet Connect',
      description: 'Scan qrcode with your mobile wallet'
    },
    package: WalletConnectProvider,
    options: {
      infuraId: '1ac9d33cb2ac432d95cd5c5e0d207174', // required
      rpc: { 56: 'https://bsc-dataseed1.defibit.io/' },
      network: 'binance'
    }
  }
}

const getWeb3Modal = () =>
  new Web3Modal({
    cacheProvider: true, // optional
    providerOptions // required
  })

const getProvider = async providerName => {
  try {
    const web3Modal = getWeb3Modal()
    return await web3Modal.connectTo(providerName)
  } catch (e) {
    throw e
  }
}
export const useInjected = () => {
  const { wallet_, web3Modal_ } = useContext(WalletContext)
  const [wallet, setWallet] = wallet_

  const connect = async () => {
    try {
      const instance = await getProvider('injected')
      const account = await getAccount(instance)
      setWallet(account)
    } catch (e) {
      throw e
    }
  }

  return connect
}

export const useWalletConnect = () => {
  const { wallet_ } = useContext(WalletContext)
  const [wallet, setWallet] = wallet_

  const connect = async () => {
    try {
      const instance = await getProvider('walletconnect')
      const account = await getAccount(instance)
      setWallet(account)
    } catch (e) {
      throw e
    }
  }

  return connect
}
//
// export const useConnect = () => {
//   const { wallet_ } = useContext(WalletContext)
//   const [wallet, setWallet] = wallet_
//
//   useEffect(() => {
//     const connect = async () => {
//       try {
//         const web3Modal = getWeb3Modal()
//
//         const instance = await web3Modal.connect()
//         const account = await getAccount(instance)
//         setWallet(account)
//         return instance
//       } catch (e) {
//         throw e
//       }
//     }
//   }, [])
// }

export const useDisconnect = () => {
  const { wallet_ } = useContext(WalletContext)
  const [wallet, setWallet] = wallet_

  const disconnect = async () => {
    try {
      const web3Modal = getWeb3Modal()
      await web3Modal.clearCachedProvider()
      setWallet(null)
    } catch (e) {
      throw e
    }
  }
  return disconnect
}
export const useInit = () => {
  const { wallet_ } = useContext(WalletContext)
  const [wallet, setWallet] = wallet_

  const subscribeProvider = async provider => {
    if (!provider.on) {
      return
    }
    provider.on('close', () => window.location.reload())
    provider.on('accountsChanged', async accounts => {
      await setWallet(accounts[0])
      window.location.reload()
    })
    provider.on('chainChanged', async chainId => {
      console.log(chainId)
      window.location.reload()
    })
  }
  const connect = async () => {
    try {
      const web3Modal = getWeb3Modal()

      const instance = await web3Modal.connect()
      subscribeProvider(instance)
      return instance
    } catch (e) {
      throw e
    }
  }
  return connect
}

const getAccount = async instance => {
  const provider = new ethers.providers.Web3Provider(instance)
  const signer = provider.getSigner()
  return await signer.getAddress()
}

export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }
  return [storedValue, setValue]
}
