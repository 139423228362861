import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './css/Home.module.css'
import stylesHeading from './css/Heading.module.css'
import stylesToken from './css/Token.module.css'
import stylesVideo from './css/BgVideo.module.css'
import stylesCommunity from './css/Community.module.css'
import stylesCarousel from './css/Carousel.module.css'
import stylesContract from './css/ContractDetails.module.css'
import stylesRoadmap from './css/Roadmap.module.css'
import phoneImage from '../../images/iPhone 12 _ 12 Pro.png'
import phoneframe from '../../images/phone-frame.png'
import spiralSVG from '../../images/svg/spiralSVG.svg'
import Button from '../../components/Button'
import Timer from '../../components/Timer'
import BackgroundVideo from '../../components/BgVideo'
import earthVideo from '../../images/video/1920x1080.mp4'
import phoneVideo from '../../images/video/HMMM_Exchange_3.mp4'
import arImage from '../../images/arImage.png'
import hmmCommunity from '../../images/hmmCommunity.png'
import hmmStaking from '../../images/hmmStaking.png'
import mainLogo from '../../images/Logo-Main.png'
import plus from '../../images/plus.png'
import upArrow from '../../images/upArrow.png'
import { Col, Container, Row } from "react-bootstrap";
import Carousel, { CarouselItem } from "../../components/Carousel/Carousel";
import FeatureCardItem from "../../components/FeaturesCardItem";
import { useHistory } from "react-router-dom";

const startDate = new Date("2022-01-18");

startDate.setDate(startDate.getDate()+257);

const Home = () => {
const history = useHistory();
const handleChange = () => history.push('/v2/stake');
const [offsetY, setOffsetY] = useState(0)
const handleScroll = () => {
setOffsetY(window.pageYOffset)
}
useEffect(() => {
window.addEventListener('scroll', handleScroll)
return () => window.removeEventListener('scroll', handleScroll)
}, [])
const socialDetails = [
    {
      id:1,
      name: 'tiktok ',
      value: '950,000'
    },
    {
      id:2,
      name: 'instagram ',
      value: '36,000'
    },
    {
      id:3,
      name: 'holders ',
      value: '2,343'
    },
    {
      id:4,
      name: 'telegram ',
      value: '6,000'
    },
    ]
return (
<>
<div className={cn(styles['home-container'])}>
  <div className={cn(stylesHeading['heading'])} >
    <p>Get Ready For</p>
    <h2 className={cn(stylesHeading['heading-ani'])}>Real-World</h2>
    <div className={cn(stylesHeading['heading-ani'])}>
      <h2 style={{transform:`translateY(${offsetY * 0.016}vh)`}}>N</h2>
      <h2 style={{transform:`translateY(${offsetY * 0.03}vh)`}}>F</h2>
      <h2 style={{transform:`translateY(${offsetY * 0.012}vh)`}}>T'</h2>
      <h2 style={{transform:`translateY(${offsetY * 0.02}vh)`}}>S</h2>
    </div>
  </div>
  
  <div className={cn(stylesHeading['phone'])} >
    {/* <img src={phoneImage}></img> */}
    <img className={cn(stylesHeading['phone-frame'])} src={phoneframe}></img>
    <video autoPlay muted className={cn(stylesHeading['video'])}>
      <source src={phoneVideo} type="video/mp4" />
    </video>
    <div
      className={cn(stylesHeading['phone-back'])}
   ></div>
    <hr
    className={cn(stylesHeading['hr'])}
   ></hr>
  </div>
  <div className={cn(stylesHeading['sub-heading'])} >
    <h1>The ability to create, publish, distribute and exchange 3D content using Augmented Reality is coming soon.</h1>
    <div className={cn(stylesHeading['button-group'])}>
      <div>
        <Button label={"TOKEN"} primary handleClick={"https://dex-trade.com/spot/trading/HMMMUSDT"}/>
        <p className={cn(stylesHeading['subtext-invest'])}>Invest in HMMM</p>
      </div>
      <div>
        <Button className={cn(stylesHeading["dummy"])} label={"APP"} gradient handleClick={"/exchange"}/> 
        <p className={cn(stylesHeading['subtext-learn'])}>Learn about the app</p>
      </div>
    </div>
  </div>
  
  <Timer startDate={startDate}/>
  
  <div className={cn(stylesToken['flex-token-container'])} id="features">
    <div></div>
    <div>
      <img src={plus}></img>
      <img src={plus}></img>
      <img src={plus}></img>
      <img src={plus}></img>
      <h2 className={cn(stylesToken['hmmmtoken'])}>#hmmmtoken</h2>
    </div>
    <div>
      <p>STAKING</p>
      <p>NFT’S</p>
      <p>REWARDS</p>
      <p className={cn(stylesToken['and-more'])}>AND MORE</p>
    </div>
    <div>
      <ul>
        <li>NFT exchange</li>
        <li>Cooperative/sole search for NFTs.</li>
        <li>Custom NFT creation and placement.</li>
        <li>Curated Collections.</li>
        <li>Augmented Reality Viewer</li>
      </ul>
    </div>
    <div>
      <h1>NFT</h1>
      <p>
        <span><img src={upArrow}/></span>
        <span><img src={upArrow}/></span>
        <span><img src={upArrow}/></span>
      </p>
    </div>
  </div>
  <BackgroundVideo videoSource={earthVideo}>
  <div className={cn(stylesCommunity['scroller'])}>
    <div style={{transform:`translateX(-${offsetY * 0.03}vh)`}} className={cn(stylesCommunity['ticker'])} >
            {
              socialDetails.map(details=>{
                return <h4 key={details.id}>{details.name}<span>{details.value}</span></h4>
              })
            }
            {
              socialDetails.map(details=>{
                return <h4 className={cn(stylesCommunity['hide-mobile'])} key={details.id}>{details.name}<span>{details.value}</span></h4>
              })
            }
            {
              socialDetails.map(details=>{
                return <h4 className={cn(stylesCommunity['hide-mobile'])} key={details.id}>{details.name}<span>{details.value}</span></h4>
              })
            }
            {
              socialDetails.map(details=>{
                return <h4 className={cn(stylesCommunity['hide-mobile'])} key={details.id}>{details.name}<span>{details.value}</span></h4>
              })
            }
            {
              socialDetails.map(details=>{
                return <h4 className={cn(stylesCommunity['hide-mobile'])} key={details.id}>{details.name}<span>{details.value}</span></h4>
              })
            }
    </div>

  </div>
  
  </BackgroundVideo >
  
  <div className={cn(stylesCommunity['grid-container'])}>
    <Row className={cn(stylesCommunity['parallax-container'])}>
    <Col className={cn(stylesCommunity['parallax-num'])} lg={3}><h2 className={cn(stylesCommunity['z-sky'])}>01</h2></Col>
    <Col className={cn(stylesCommunity['parallax-img'])} lg={5}><img style={{transform:`scale(${offsetY * .00017 +.5})`}} className={cn(stylesCommunity['p-1'])} src={arImage}></img></Col>
    <Col className={cn(stylesCommunity['parallax'])} lg={4}>
    <div className={cn(stylesCommunity['copy'])}>
      <p><small>Discover</small></p>
      <h3>Augmented Reality</h3>
      <p>Using an AR capable device, you can explore hidden experiences, rare content, and secret artwork in real locations around the world.</p>
      <Button className={cn(stylesVideo['ar-button'])} label="Details" primary handleClickNewTab="https://hmmm.boblles.com/white-paper.pdf"/>
    </div>
    </Col>
    </Row>
    <Row className={cn(stylesCommunity['parallax-container'])}>
    <div className={cn(stylesCommunity['reverse'])}>
    <Col className={cn(stylesCommunity['parallax'])} lg={4}>
    <div className={cn(stylesCommunity['copy-right'])}>
      <p><small>Connect</small></p>
      <h3>HMMM COMMUNITY</h3>
      <p>Be the first to learn about upcoming features, news, private sale tokens, rewards, and prizes.</p>
      <Button className={cn(stylesVideo['ar-button'])} label="Join Us" primary icon={true} handleClick="https://t.me/hmmmtoken"/>
    </div>
    </Col>
    <Col className={cn(stylesCommunity['parallax-img'])} lg={5}><img style={{transform:`scale(${offsetY * .00017 +.5})`}} className={cn(stylesCommunity['p-2'])} src={hmmCommunity}></img></Col>
    <Col className={cn(stylesCommunity['parallax-num'])} lg={3}><h2 className={cn(stylesCommunity['tuck-left'])}>02</h2></Col>
    </div>
    </Row>
    <Row className={cn(stylesCommunity['parallax-container'])}>
    <Col  className={cn(stylesCommunity['parallax-num'])} lg={3}><h2>03</h2></Col>
    <Col className={cn(stylesCommunity['parallax-img'])} lg={5}><img style={{transform:`scale(${offsetY * .00017 + .5})`}}  className={cn(stylesCommunity['p-3'])} src={hmmStaking}></img></Col>
    <Col className={cn(stylesCommunity['parallax'])} lg={4}>
    <div className={cn(stylesCommunity['copy'])}>
      <p><small>Grow</small></p>
      <h3>HMMM Staking</h3>
      <p><span className={cn(styles['special'])}>Earn 24% APY.</span> Stake HMMM and maximize your investment. Let your account grow and experience a passive income with rewards.</p>
      <Button className={cn(stylesVideo['ar-button'])} label="Stake Now" primary onClick={handleChange}/>
    </div>
    </Col>
    </Row>
  </div>
  <div className={cn(stylesCarousel['carousel-container'])}>
    
    <div className={cn(stylesCarousel['carousel-container-main'])} >
      <div className={cn(stylesContract['contract-details'])} id="contract">

    
      <div className={cn(stylesCarousel['features-row'])}>
      <div className={cn(stylesCarousel['heading-details'])}>
        <Row>
        <Col lg={1}>
        <div className={cn(stylesCarousel['details-logo'])}>
        <img className={cn(stylesCarousel['features-logo'])} src={mainLogo}></img>
        </div>
        </Col>
        <Col lg={1}>
        <div className={cn(stylesCarousel['features-hero'])}>
          <h6>Token</h6>
          <h3>HMMM</h3>
          <div className={cn(stylesCarousel['features-btn'])}><Button label={"BUY (CEX)"} primary handleClick={"https://dex-trade.com/spot/trading/HMMMUSDT"}/><Button label={"BUY (DEX)"} primary handleClick={"https://pancakeswap.finance/swap?outputCurrency=0x88Cf50037f8069071Cd56f641a81b369a5Fd3a42"}/></div>
        </div>
        </Col>
        <Col lg={5}>
        <div className={cn(stylesCarousel['features-hero'])}>
          <h6>Address</h6>
          <h3 style={{textTransform: "none"}}>0x88Cf50037f8069071Cd56f641a81b369a5Fd3a42</h3>
        </div>
        </Col>
        <Col lg={2}>
        <div className={cn(stylesCarousel['features-hero'])}>
          <h6>NETWORK</h6>
          <h3>Binance Smart Chain</h3>
          <h6>Total Supply</h6>
          <h3>40,000,000</h3>
        </div>
        </Col>
        <Col lg={2}>
        <div className={cn(stylesCarousel['features-hero'])}>
          <h6>Max Supply</h6>
          <h3>40,000,000</h3>
          <h6>Curculating Supply (minus burned amount)</h6>
          <h3>40,000,000</h3>
        </div>
        </Col>
        <Col lg={1}>
        <div className={cn(stylesCarousel['features-hero'])}>
          <h6>Fees</h6>
          <h3>0%</h3>
          <h6>Slippage</h6>
          <h3>0.1%</h3>
        </div>
        </Col>
        </Row>
        </div>
        <div className={cn(stylesCarousel['carousel-container-sub'])} >
        <Carousel>
        <CarouselItem>
        <Row>
        <Col lg={4}><FeatureCardItem percentage="100%" title="Locked Pool" subtitle="All liquidity pool tokens have been burned to secure the platform." buttonLabel="View Transaction" handleClick="https://bscscan.com/tx/0x12b5f53bbd88b535ff33b3fbe53a91f2e56d8b32d039ae7fe087fda6d55a6e32"/></Col>
        <Col lg={4}><FeatureCardItem percentage="1M" title="Burn" subtitle="Around 1M tokens burned every month with a maximum of 17M" buttonLabel="View Transactions" handleClick="https://bscscan.com/token/0x88cf50037f8069071cd56f641a81b369a5fd3a42?a=0x000000000000000000000000000000000000dead"/></Col>
        <Col lg={4}><FeatureCardItem percentage="Verified" title="Contract" subtitle="The HMMM Token contract is publically deployed and verified by BscScan. " buttonLabel="View Contract" handleClick="https://bscscan.com/address/0x88Cf50037f8069071Cd56f641a81b369a5Fd3a42"/></Col>
        </Row>
        </CarouselItem>
        </Carousel>
      </div>s
      </div>
      
      <div className={cn(stylesCarousel['carousel-container-footer'])}>
        <p>CURRENT HOLDERS</p>
        <h2>2,343</h2>
      </div>
  </div>
    </div>
    
  </div>
  <div className={cn(stylesRoadmap['roadmap-container'])} id="roadmap">
    <div className={cn(stylesRoadmap['roadmap'])}>
      <div className={cn(stylesRoadmap['hideBorder'])}></div>
      <div className={cn(stylesRoadmap['thickBorder'])} ></div>
      <div className={cn(stylesRoadmap['row-a'])}>
        <div className={cn(stylesRoadmap['content-roadmap-1'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>Token Launch</p>
          <small>November</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-2'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>Dex-trade.com Listing</p>
          <small>January</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-3'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>HMMM Staking</p>
          <small>January</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-4'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>CoinMarketCap Listing</p>
          <small>February</small>
        </div>
      </div>
      <div className={cn(stylesRoadmap['row-b'])}>
        <div className={cn(stylesRoadmap['content-roadmap-5'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>Marketing Campaign</p>
          <small>August</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-6'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>HMMM App Launch</p>
          <small>August</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-7'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>HMMM App Beta</p>
          <small>July</small>
        </div>
        <div className={cn(stylesRoadmap['content-roadmap-8'])}>
          <div className={cn(stylesRoadmap['dot'])}></div>
          <p>HMMM App Alpha</p>
          <small>June</small>
        </div>
      </div>
    </div>
  </div>
</div>
</>
)
}
export default Home