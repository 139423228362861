import React, { useState, useEffect, useRef, useContext } from 'react'
import cn from 'classnames'
import styles from './Stake.module.css'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { useStake, useInterval } from '../../contract'
import { ethers } from 'ethers'
import toast from 'react-hot-toast'
import { WalletContext } from '../../WalletProvider'

const Stake = () => {
  const textRef = useRef()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [balance, setBalance] = useState(0)
  const [staked, setStaked] = useState(0)
  const [earn, setEarn] = useState(0)
  const [action, setAction] = useState('unstake')
  const [refresh, setRefresh] = useState(0)
  const [isApproved, setIsApproved] = useState(false)

  const {
    approve,
    isApprove,
    stake,
    withdraw,
    balanceOf,
    stakedAmount,
    earned,
    getReward
  } = useStake()

  const { wallet_ } = useContext(WalletContext)
  const account = wallet_[0]

  useEffect(() => {
    balanceOf(account)
      .then(_balance => setBalance(ethers.utils.formatUnits(_balance, 9)))
      .catch(e => console.log(e))

    stakedAmount(account)
      .then(_staked => setStaked(ethers.utils.formatUnits(_staked, 9)))
      .catch(e => console.log(e))

    earned(account)
      .then(_earn => setEarn(ethers.utils.formatUnits(_earn, 9)))
      .catch(e => console.log(e))
  }, [account, refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isApprove()
      .then(res => {
        if (res === false) setIsApproved(true)
      })
      .catch(e => console.log(e))
  }, [])

  useInterval(
    () => {
      earned(account)
        .then(_earn => setEarn(ethers.utils.formatUnits(_earn, 9)))
        .catch(e => e)
    },
    staked > 0 ? 30000 : null
  )

  const onApprove = async () => {
    const amount = textRef.current.value

    if (amount === '') return

    setIsSubmiting(true)
    try {
      await approve()
      setIsApproved(true)
    } catch (e) {
      console.log(e)
    }
    setIsSubmiting(false)
  }

  const onSubmit = async () => {
    const amount = textRef.current.value

    if (amount === '') return

    setIsSubmiting(true)
    try {
      await stake(amount)
      setRefresh(new Date())
      toast('Staked successfully.')
    } catch (e) {
      console.log(e)
    }
    setIsSubmiting(false)
  }

  const onSubmitUnstake = async () => {
    const amount = textRef.current.value

    if (amount === '') return

    setIsSubmiting(true)
    try {
      await withdraw(amount)
      setRefresh(new Date())
      toast('Unstaked successfully.')
    } catch (e) {
      console.log(e)
    }
    setIsSubmiting(false)
  }

  const handleRewards = async () => {
    try {
      await getReward()
      setRefresh(new Date())
      toast('Reward received successfully.')
    } catch (e) {
      console.log(e)
    }
  }

  const handleTab = action => setAction(action)

  const handleMax = () => {
    if (action === 'unstake') {
      textRef.current.value = staked
    }
  }

  return (
    <>
      <div className={cn(styles['stake-container'])}>
        <div className={cn(styles['stake'])}>
          <div className={cn(styles['stakeDivOne'])}>
          <h1>V1</h1>
            <h1>
            STAKE HMMM <span> EARN MORE</span>
            </h1>
          </div>
          <div className={cn(styles['stackBackground'])}>
            <div className={cn(styles['stack-tab-container'])}>
              <div className={cn(styles['one'])}>
                <p>Move to</p>
                <h2>V2</h2>
              </div>

              <div className={cn(styles['two'])}>
                <p>Move to</p>
                <h2>V2</h2>
              </div>
              <div className={cn(styles['three'])}>
                <p>Move to</p>
                <h2>V2</h2>
              </div>
            </div>

            <div className={cn(styles['tabs-wrapper'])}>
              <div className={cn(styles['tabs'])}>
              <p
                  className={action === 'unstake' ? cn(styles['active']) : ''}
                  onClick={() => handleTab('unstake')}>
                  Unstake
                </p>
              </div>
            </div>

            <div className={cn(styles['input-container-main'])}>
              <div className={cn(styles['input-container'])}>
                <TextInput
                  className={cn(styles['text-container'])}
                  placeholder={'Amount'}
                  ref={textRef}
                />
                <Button label={'Max'} onClick={handleMax} />
              </div>
              <div className={cn(styles['stake-button'])}>
                  <Button
                    label={'UNSTAKE HMMM'}
                    primary
                    onClick={onSubmitUnstake}
                    isLoading={isSubmiting}
                  />
              </div>
            </div>

            <div className={cn(styles['information'])}>
              <div>Your Balance</div>
              <div>{balance} HMMM</div>
              <div>Your Staked Balance</div>
              <div>{staked} HMMM</div>

              <div>Reward Earned</div>
              <div>
                <span className={cn(styles['rewards'])} onClick={handleRewards}>
                  Get Rewards
                </span>
                {earn} HMMM
              </div>
            </div>
            <div className={cn(styles['legal'])}>
              <p>
                This is the old stake contract. Which will be disabled as announced on telegram.
                The rewards will drop to 0% and we ask you to unstake and claim the rewards when the timelock is ended.
                Move to v2 stake for a stable 24% HMMM reward as a return. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Stake
