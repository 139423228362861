import React, { useEffect, useContext } from 'react'
import Modal from '../Modal'
import styles from './ConnectWalletModal.module.css'
import cn from 'classnames'
import { Col, Row } from 'react-bootstrap'
import moreWallet from '../../images/moreWallet.png'
import metaMask from '../../images/metaMask.png'
import walletConnect from '../../images/walletConnect.png'
import trustWallet from '../../images/trustWallet.png'
import scanner from '../../images/scanner.png'
import scanner2 from '../../images/QRCode.png'
import Button from '../../components/Button'
import toast from 'react-hot-toast'
import { getErrorMessage } from '../../utils'
import { useInjected, useWalletConnect } from './hooks'
import { WalletContext } from '../../WalletProvider'

function ConnectWalletModal({ visibleModal, setVisibleModal }) {
  const injectedConnector = useInjected()
  const walletConnector = useWalletConnect()
  const { wallet_ } = useContext(WalletContext)

  const resetApp = async () => {
    //await web3Modal.clearCachedProvider()
    wallet_.setWallet(null)
  }

  const activateWeb3 = () => {
    injectedConnector()
      .then(() => {
        setVisibleModal(false)
      })
      .catch(e => {
        console.log(e)
        toast.error('No Ethereum browser extension detected')
        setVisibleModal(false)
      })
  }

  const activateWalletConnect = () => {
    walletConnector()
      .then(res => {
        setVisibleModal(false)
      })
      .catch(e => {
        setVisibleModal(false)
      })
  }

  const Metamask = () => (
    <div className={cn(styles['walletType'])}>
      <div className={cn(styles['walletIcon'])}>
        <img src={metaMask} />
      </div>
      <div>
        <h2 style={{ cursor: 'pointer' }} onClick={activateWeb3}>
          Metamask
        </h2>
      </div>
    </div>
  )

  const WalletConnect = () => (
    <div className={cn(styles['walletType'])}>
      <div className={cn(styles['walletIcon'])}>
        <img src={walletConnect} />
      </div>
      <div>
        <h2 style={{ cursor: 'pointer' }} onClick={activateWalletConnect}>
          WalletConnect
        </h2>
      </div>
    </div>
  )

  return (
    <Modal
      visible={visibleModal}
      showBack={true}
      noClose={true}
      title={'Connect wallet'}
      onClose={() => {
        setVisibleModal(false)
      }}
      doNotCloseOnOutside={true}>
      <hr className={cn(styles['hr'])}></hr>
      <Row>
        <Col>
          <Metamask />
          <WalletConnect />
        </Col>
      </Row>
    </Modal>
  )
}

export default ConnectWalletModal
