import React, { createContext, useState, useEffect } from 'react'
import { useLocalStorage } from './components/ConnectWalletModal/hooks'
export const WalletContext = createContext()

export const WalletProvider = props => {
  const [wallet, setWallet] = useLocalStorage('wallet', null)

  return (
    <WalletContext.Provider
      value={{
        wallet_: [wallet, setWallet]
      }}>
      {props.children}
    </WalletContext.Provider>
  )
}
