import React, { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./style.module.css";
import { MdArrowBack, MdClose } from "react-icons/md";

const Modal = ({
  outerClassName,
  containerClassName,
  visible,
  onClose,
  children,
  bigModal,
  doNotCloseOnOutside,
  width,
  showStatus,
  title,
  subTitle,
  animation,
  showBack = false,
  noClose = false,
  fullScreenModal = false,
  hideEsc = false,
  modalContainerClass = '',
}) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const scrollRef = useRef(null);

  useEffect(() => {
    visible ? disableBodyScroll(scrollRef) : enableBodyScroll(scrollRef);
  }, [visible]);

  return createPortal(
    visible && (
      <div className={cn(styles.modal,modalContainerClass ? styles[modalContainerClass]:null,animation == "fade-up" ? styles.fadeUp : null )} ref={scrollRef}>
        <div
          className={cn(
            styles.outer,
            bigModal ? styles.bigOuter : null,
            fullScreenModal ? styles.fullScreen : null,
            outerClassName
          )}
        >
          <OutsideClickHandler
            onOutsideClick={() => {
              if (!doNotCloseOnOutside) {
                onClose();
              }
            }}
          >
            {showBack ? (
              <div className={styles.header}>
                <div className={styles.back}>
                  <MdArrowBack size={35} onClick={onClose} />
                </div>
                {
                  title ? <div><h1 className={cn(styles['title'])}>{title}</h1></div> : null
                }
              </div>
            ) : null}
            <div className={cn(styles.container, containerClassName)}>
              {children}
            </div>
          </OutsideClickHandler>
        </div>

        {!noClose ? (
          <button className={styles.close} onClick={onClose}>
            <MdClose name="close" size="14" />
            {!hideEsc ? <label className={styles.closeText}>Esc</label> : null}
          </button>
        ) : null}
      </div>
    ),
    document.body
  );
};

export default Modal;
