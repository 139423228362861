import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import logo from './logo.svg'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Page from './components/Page'
import Home from './screens/Home'
import Stakev2 from './screens/v2/Stake'
import Stake from './screens/Stake'
import Exchange from './screens/Exchange'
import Refer from './screens/Refer'
import { WalletProvider } from './WalletProvider'

const App = () => {
  return (
    <div>
      <WalletProvider>
        <Router>
          <Switch>
            <Route
              exact
              path={'/'}
              render={() => (
                <Page footerSmall={true}>
                  <Home />
                </Page>
              )}
            />
            <Route
              exact
              path={'/v2/stake'}
              render={() => (
                <Page footerSmall={true}>
                  <Stakev2 />
                </Page>
              )}
            />
            <Route
              exact
              path={'/stake'}
              render={() => (
                <Page footerSmall={true}>
                  <Stake />
                </Page>
              )}
            />
            <Route
              exact
              path={'/exchange'}
              render={() => (
                <Page footerSmall={true}>
                  <Exchange />
                </Page>
              )}
            />
            {/* <Route
              exact
              path={'/refer'}
              render={() => (
                <Page footerSmall={true}>
                  <Refer />
                </Page>
              )}
            /> */}
          </Switch>
        </Router>
      </WalletProvider>
    </div>
  )
}

export default App
