import React from 'react'
import cn from 'classnames'
import styles from './Button.module.css'
import { Button } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'

const Radio = React.forwardRef(
  (
    {
      className,
      label,
      handleClick,
      handleClickNewTab,
      primary,
      gradient,
      buttonClass,
      icon,
      full,
      isLoading = false,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cn(styles.field, className, '')}>
        <div className={styles.wrap}>
          <Button
            onClick={
              handleClickNewTab
                ? e => window.open(`${handleClickNewTab}`)
                : handleClick
                ? e => {
                    window.location.href = `${handleClick}`
                  }
                : props.onClick
            }
            className={[
              styles.button,
              primary ? styles.primary : '',
              gradient ? styles.gradient : '',
              full ? styles.full : ''
            ]}
            {...props}>
            <div className={cn(styles['button-wrapper'])}>
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}

              {label && (
                <div className={cn(styles.label, buttonClass)}>
                  {icon ? (
                    <span className={cn(styles['icon'])}>
                      <svg
                        width="29"
                        height="25"
                        viewBox="0 0 29 25"
                        fill="vlack"
                        xmlns="http://www.w3.org/2000/svg">
                        {' '}
                        <path d="M28.9739 1.5309L24.4787 23.7004C24.4243 23.9685 24.3055 24.2183 24.1333 24.4266C23.9611 24.635 23.741 24.7953 23.4932 24.8929C23.2454 24.9904 22.9778 25.0221 22.7151 24.9849C22.4523 24.9477 22.2028 24.8429 21.9895 24.6802L15.4509 19.6898L11.4895 23.5505C11.4376 23.601 11.3765 23.6404 11.3098 23.6663C11.2431 23.6921 11.172 23.704 11.1008 23.7012C11.0295 23.6984 10.9595 23.681 10.8949 23.65C10.8302 23.6189 10.7722 23.5749 10.7242 23.5204L10.6391 23.4238L11.3274 16.5426L23.7336 4.83287C23.795 4.77506 23.8331 4.69559 23.8405 4.61015C23.8479 4.52471 23.8241 4.43949 23.7737 4.37132C23.7233 4.30314 23.65 4.25695 23.5682 4.24187C23.4865 4.22678 23.4022 4.2439 23.3321 4.28983L7.46609 14.6635L0.632922 12.2928C0.451232 12.2298 0.292878 12.1102 0.179587 11.9505C0.0662957 11.7907 0.00361772 11.5987 0.000151901 11.4008C-0.00331391 11.2028 0.0526026 11.0086 0.160229 10.8447C0.267856 10.6809 0.42192 10.5555 0.601285 10.4857L27.3368 0.0814184C27.543 0.00124452 27.7663 -0.0202235 27.9833 0.0192587C28.2004 0.058741 28.4031 0.157723 28.5704 0.305858C28.7377 0.453993 28.8635 0.645839 28.9344 0.861341C29.0054 1.07684 29.0191 1.30809 28.9739 1.5309Z" />
                      </svg>
                    </span>
                  ) : null}
                  {label}
                </div>
              )}
            </div>
          </Button>
        </div>
      </div>
    )
  }
)

export default Radio
